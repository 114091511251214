import Preview from "../shared/models/Preview";
import ProjectItem from "../shared/models/ProjectItem";
import {
  EXPENSE_SKILLS,
  LMS_SKILLS,
  // RECIPE_SKILLS,
  STOCKFOLIO_SKILLS,
  SAAS_SKILLS,
  PANTRY_SKILLS,
  PROMPT_SKILLS,
  ASONE_SKILLS,
  CM_SKILLS,
  BIAMINO_SKILLS,
  GHAI_SKILLS,
  AUTOMATA_SKILLS
} from "./skills";

export const REDDIT_ID = "reddit-clone"
export const SAAS_ID = "saas"
export const RECIPE_ID = "recipe"
export const HERSEYDEVELOPERS_ID = "herseydevelopers"
export const HSHACKS_ID = "hshacks"
export const STOCKFOLIO_ID = "stockfolio"
export const LMS_ID = "lms"
export const EXPENSE_ID = "expense"
export const PANTRY_ID = "pantry"
export const PROMPT_ID = "prompt"
export const POSTBUD_ID = "postbud"
export const ASONE_ID = "asone"
export const CM_ID = "cm"
export const BIAMINO_ID = "biamino"
export const GHAI_ID = "ghai"
export const AUTOMATA_ID = "automata"

export const PROJECTS = [

  new ProjectItem(
    AUTOMATA_ID,
    AUTOMATA_ID,
    "automata.",
    "automata.",
    "A platform that enables users to connect various platforms and create sophisticated automation pipelines.",
    "A platform that enables users to connect various platforms and create sophisticated automation pipelines.",
    AUTOMATA_ID,
    AUTOMATA_SKILLS,
    "automata.",
    "https://cookie-paper-810.notion.site/Automata-Documentation-15034142cd0c806a8a1bc8803dee22a4?pvs=74",
    [
      "Developed a platform that enables users to connect various platforms and create sophisticated automation pipelines.",
    ],
    [
      new Preview("/screenshots/automata/laptop-1.png", "laptop"),
    ],
    [
      new Preview("/screenshots/automata/laptop-1.png", "laptop"),
      new Preview("/screenshots/automata/laptop-2.png", "laptop"),
      new Preview("/screenshots/automata/laptop-3.png", "laptop"),
      new Preview("/screenshots/automata/laptop-7.png", "laptop"),
      new Preview("/screenshots/automata/laptop-8.png", "laptop"),
      new Preview("/screenshots/automata/laptop-4.png", "laptop"),
      new Preview("/screenshots/automata/laptop-6.png", "laptop"),
      new Preview("/screenshots/automata/laptop-5.png", "laptop"),
    ], // Leave this empty for previews
    [], // Leave this empty for videos
    null, // Leave this blank if no app demo link
    null, // Leave this blank if no web demo link
    "" // Leave this blank if no GitHub link
  ),
  
  new ProjectItem(
    GHAI_ID,
    LMS_ID,
    "GrowthHungry AI",
    "GrowthHungry AI",
    "AI LMS and Community Platform",
    "AI LMS and Community Platform",
    GHAI_ID,
    GHAI_SKILLS,
    "GrowthHungry",
    "https://www.growthhungry.life/",
    [
      "Developed a powerful learning management system (LMS) and community platform that leverages AI capabilities to enhance learning experiences for students and instructors",
    ],
    [
      new Preview("/screenshots/ghai/laptop-6.png", "laptop"),
    ],
    [
      new Preview("/screenshots/ghai/laptop-1.png", "laptop"),
      new Preview("/screenshots/ghai/laptop-2.png", "laptop"),
      new Preview("/screenshots/ghai/laptop-3.png", "laptop"),
      new Preview("/screenshots/ghai/laptop-4.png", "laptop"),
      new Preview("/screenshots/ghai/laptop-5.png", "laptop"),
      new Preview("/screenshots/ghai/laptop-6.png", "laptop"),
      new Preview("/screenshots/ghai/laptop-7.png", "laptop"),
      new Preview("/screenshots/ghai/laptop-8.png", "laptop"),
      new Preview("/screenshots/ghai/laptop-9.png", "laptop"),
      new Preview("/screenshots/ghai/laptop-10.png", "laptop"),
      new Preview("/screenshots/ghai/laptop-11.png", "laptop"),
      new Preview("/screenshots/ghai/laptop-12.png", "laptop"),
      new Preview("/screenshots/ghai/laptop-13.png", "laptop"),
      new Preview("/screenshots/ghai/laptop-14.png", "laptop"),
      new Preview("/screenshots/ghai/laptop-15.png", "laptop"),
      new Preview("/screenshots/ghai/laptop-16.png", "laptop"),
      new Preview("/screenshots/ghai/phone-1.png", "laptop"),
      new Preview("/screenshots/ghai/phone-2.png", "laptop"),
      new Preview("/screenshots/ghai/phone-3.png", "laptop"),

    ], // Leave this empty for previews
    [], // Leave this empty for videos
    null, // Leave this blank if no app demo link
    null, // Leave this blank if no web demo link
    "" // Leave this blank if no GitHub link
  ),
  
  new ProjectItem(
    BIAMINO_ID,
    BIAMINO_ID,
    "Biamino",
    "Biamino",
    "Biamino changes traditional business models through the implementation of innovative AI solutions.",
    "Biamino changes traditional business models through the implementation of innovative AI solutions that transform approaches to doing business and increase its competitiveness.",
    BIAMINO_ID,
    BIAMINO_SKILLS,
    "Biamino",
    "https://www.biamino.tech/",
    [
      "Designed and implemented an AI-powered chatbot for Cortex Medical Center, trained on in-house datasets and open-source solutions, reducing operator response time from an average of 40 minutes to instant replies.",
      "Integrated appointment scheduling with a custom database, enabling patients to book open slots autonomously and reducing reliance on staff intervention.",
      "Configured AI to provide medical guidance on protocols like pre-blood-donation requirements and other health guidelines, delivering personalized responses for patient inquiries."
    ],
    [
    ],
    [
    ], // Leave this empty for previews
    [], // Leave this empty for videos
    null, // Leave this blank if no app demo link
    "", // Leave this blank if no web demo link
    null // Leave this blank if no GitHub link
  ),


  new ProjectItem(
    LMS_ID,
    LMS_ID,
    "GrowthHungry Academy",
    "GrowthHungry Academy",
    "GrowthHungry Academy Learning Management System",
    "GrowthHungry Academy Learning Management System",
    LMS_ID,
    LMS_SKILLS,
    "GrowthHungry",
    "https://www.growthhungry.life/",
    [
      "Created a scalable Learning Management System for GrowthHungry Academy to manage courses, students, and instructors",
    ],
    [
      new Preview("/screenshots/lms/laptop-3.png", "laptop"),
    ],
    [
      new Preview("/screenshots/lms/laptop-1.png", "laptop"),
      new Preview("/screenshots/lms/laptop-2.png", "laptop"),
      new Preview("/screenshots/lms/laptop-3.png", "laptop"),
      new Preview("/screenshots/lms/laptop-4.png", "laptop"),
      new Preview("/screenshots/lms/laptop-5.png", "laptop"),
      new Preview("/screenshots/lms/laptop-6.png", "laptop"),
      new Preview("/screenshots/lms/laptop-7.png", "laptop"),
      new Preview("/screenshots/lms/laptop-8.png", "laptop"),
      new Preview("/screenshots/lms/phone-1.png", "laptop"),
      new Preview("/screenshots/lms/phone-2.png", "laptop"),
      new Preview("/screenshots/lms/phone-3.png", "laptop"),
      new Preview("/screenshots/lms/phone-4.png", "laptop"),
      new Preview("/screenshots/lms/phone-5.png", "laptop"),
      new Preview("/screenshots/lms/phone-6.png", "laptop"),
      new Preview("/screenshots/lms/phone-7.png", "laptop"),
      new Preview("/screenshots/lms/phone-8.png", "laptop"),
      new Preview("/screenshots/lms/phone-9.png", "laptop"),
    ], // Leave this empty for previews
    ["uoqkHK21nTE"], // Leave this empty for videos
    null, // Leave this blank if no app demo link
    null, // Leave this blank if no web demo link
    "https://github.com/ArslanKamchybekov/GrowthHungry_Academy" // Leave this blank if no GitHub link
  ),

  new ProjectItem(
    CM_ID,
    CM_ID,
    "AI College Marketplace",
    "AI College Marketplace",
    "College Marketplace Platform",
    "College Marketplace Platform",
    CM_ID,
    CM_SKILLS,
    "College Marketplace",
    null,
    [
      "Developed a marketplace platform for college students to buy, sell, and trade items with other students",
    ],
    [
      
    ],
    [
      
    ], // Leave this empty for previews
    [], // Leave this empty for videos
    null, // Leave this blank if no app demo link
    null, // Leave this blank if no web demo link
    "https://github.com/ArslanKamchybekov/college-marketplace" // Leave this blank if no GitHub link
  ),


  new ProjectItem(
    ASONE_ID,
    ASONE_ID,
    "AsOne",
    "AsOne",
    "A personalized wellness and relationship management platform",
    "A personalized wellness and relationship management platform",
    ASONE_ID,
    ASONE_SKILLS,
    "AsOne app",
    null,
    [
      "Developed a comprehensive wellness and relationship management platform that allows users to track and predict their menstrual cycles, mood, and relationships",
    ],
    [
      new Preview("/screenshots/asone/phone-1.png", "phone"),
    ],
    [
      new Preview("/screenshots/asone/phone-1.png", "laptop"),
    ], // Leave this empty for previews
    [], // Leave this empty for videos
    null, // Leave this blank if no app demo link
    null, // Leave this blank if no web demo link
    "https://github.com/tgrowth/asone-ios" // Leave this blank if no GitHub link
  ),

  new ProjectItem(
    STOCKFOLIO_ID,
    STOCKFOLIO_ID,
    "Finance Management System",
    "Finance Management System",
    "Finance management system with basic ML capabilities",
    "Finance management system with basic ML capabilities",
    STOCKFOLIO_ID,
    STOCKFOLIO_SKILLS,
    "Stockfolio.ai",
    null,
    [
      "Built a Finance Management tool to enable financial management operations and a Data Regression tool to enable users to predict future revenue based on previous transactions, products, and revenue"
    ],
    [
      new Preview("/screenshots/stockfolio/laptop-1.png", "laptop")
    ],
    [
      new Preview("/screenshots/stockfolio/laptop-1.png", "laptop"),
      new Preview("/screenshots/stockfolio/laptop-2.png", "laptop"),
      new Preview("/screenshots/stockfolio/laptop-3.png", "laptop")
    ], // Leave this empty for previews
    [], // Leave this empty for videos
    null, // Leave this blank if no app demo link
    null, // Leave this blank if no web demo link
    "https://github.com/ArslanKamchybekov/AI-Stock-Market-Management" // Leave this blank if no GitHub link
  ),

  new ProjectItem(
    PANTRY_ID,
    PANTRY_ID,
    "Inventory Pantry Management System",
    "Inventory Pantry Management System",
    "Inventory Pantry Tracker System with AI capabilities",
    "Inventory Pantry Tracker System system with AI capabilities",
    PANTRY_ID,
    PANTRY_SKILLS,
    "PantryTracker",
    null,
    [
      "Built a comprehensive web application designed to help users manage their pantry inventory and generate recipes based on the ingredients they have"
    ],
    [
      new Preview("/screenshots/pantry/phone-1.png", "phone"),
    ],
    [
      new Preview("/screenshots/pantry/phone-1.png", "laptop"),
      new Preview("/screenshots/pantry/phone-2.png", "laptop"),
      new Preview("/screenshots/pantry/laptop-1.png", "laptop"),
      new Preview("/screenshots/pantry/laptop-2.png", "laptop"),
    ], // Leave this empty for previews
    [], // Leave this empty for videos
    null, // Leave this blank if no app demo link
    "https://pantry-tracker-app.vercel.app/", // Leave this blank if no web demo link
    "https://github.com/ArslanKamchybekov/Pantry-tracker" // Leave this blank if no GitHub link
  ),

  new ProjectItem(
    PROMPT_ID,
    PROMPT_ID,
    "AI Prompting Platform",
    "AI Prompting Platform",
    "Prompting Platform with AI Chatbot support",
    "Prompting Platform with AI Chatbot support",
    PROMPT_ID,
    PROMPT_SKILLS,
    "AI Prompting Platform",
    null,
    [
      "Built an open-source AI prompting tool for modern world to discover, create, and share creative prompts"
    ],
    [
      (new Preview("/screenshots/prompt/phone-2.png", "laptop")),
    ],
    [
      (new Preview("/screenshots/prompt/laptop-1.png", "laptop")),
      (new Preview("/screenshots/prompt/laptop-2.png", "laptop")),
      (new Preview("/screenshots/prompt/phone-1.png", "laptop")),
      (new Preview("/screenshots/prompt/phone-2.png", "laptop")),
    ], // Leave this empty for previews
    [], // Leave this empty for videos
    null, // Leave this blank if no app demo link
    "https://getprompted.vercel.app/", // Leave this blank if no web demo link
    "https://github.com/ArslanKamchybekov/prompt-pal" // Leave this blank if no GitHub link
  ),

  new ProjectItem(
    SAAS_ID,
    SAAS_ID,
    "AI Flashcards Generator Platform",
    "AI Flashcards Generator Platform",
    "AI Flashcards Generator Platform with SaaS capabilities",
    "AI Flashcards Generator Platform with SaaS capabilities",
    SAAS_ID,
    SAAS_SKILLS,
    "AI Flashcards Generator SaaS Platform",
    null,
    [
      "Developed a SaaS platform that generates flashcards using AI to help students study more efficiently"
    ],
    [
      new Preview("/screenshots/saas/laptop-1.png", "laptop"),
    ],
    [
      new Preview("/screenshots/saas/laptop-1.png", "laptop"),
      new Preview("/screenshots/saas/laptop-2.png", "laptop"),
      new Preview("/screenshots/saas/phone-1.png", "laptop"),
      new Preview("/screenshots/saas/phone-2.png", "laptop"),
      new Preview("/screenshots/saas/phone-3.png", "laptop"),
    ], // Leave this empty for previews
    [], // Leave this empty for videos
    null, // Leave this blank if no app demo link
    "https://flashcards-saas-pi.vercel.app/", // Leave this blank if no web demo link
    "https://github.com/ArslanKamchybekov/flashcards-saas" // Leave this blank if no GitHub link
  ),

  new ProjectItem(
    EXPENSE_ID,
    EXPENSE_ID,
    "Expense Tracker",
    "Expense Tracker",
    "A mobile app that helps users track their expenses",
    "A mobile app that helps users track their expenses",
    EXPENSE_ID,
    EXPENSE_SKILLS,
    "Expense Tracker",
    null, //app link
    [
      "EasyNews offers users a personalized news experience by allowing them to select and customize their preferred news categories"
    ],
    [new Preview("/screenshots/expense/phone-1.png", "laptop")],
    [
      new Preview("/screenshots/expense/phone-1.png", "laptop"),
    ],
    [], //videos
    null, //app store link
    null,
    null
  ),

  // new ProjectItem(
  //   RECIPE_ID,
  //   RECIPE_ID,
  //   "Recipe Finder",
  //   "Recipe Finder",
  //   "A mobile app that provides users thorough recipes.",
  //   "A mobile app that provides users thorough recipes.",
  //   RECIPE_ID,
  //   RECIPE_SKILLS,
  //   "EasyRecipes",
  //   null,
  //   [
  //     "Developed an Android app that instantly finds easy, healthy, and accessible meals using a user’s available kitchen ingredients"
  //   ],
  //   [
  //     new Preview("/screenshots/recipe/phone-1.png", "laptop"),
  //   ],
  //   [
  //     new Preview("/screenshots/recipe/phone-1.png", "laptop"),
  //   ],  // Leave this empty for previews
  //   [], // Leave this empty for videos
  //   null, // Leave this blank if no app demo link
  //   null, // Leave this blank if no web demo link
  //   "https://github.com/ArslanKamchybekov/EasyRecipe" // Leave this blank if no GitHub link
  // ),

  // new ProjectItem(
  //   POSTBUD_ID,
  //   POSTBUD_ID,
  //   "Postbud",
  //   "Postbud",
  //   "An IOS Thread Clone app that allows users to post and comment on threads",
  //   "An IOS Thread Clone app that allows users to post and comment on threads",
  //   POSTBUD_ID,
  //   POSTBUD_SKILLS,
  //   "Postbud",
  //   null,
  //   [
  //     "Developed an IOS app that allows users to post and comment on threads"
  //   ],
  //   [
  //     new Preview("/screenshots/postbud/phone-9.png", "laptop"),
  //   ],
  //   [
  //     new Preview("/screenshots/postbud/phone-1.png", "phone"),
  //     new Preview("/screenshots/postbud/phone-2.png", "phone"),
  //     new Preview("/screenshots/postbud/phone-3.png", "phone"),
  //     new Preview("/screenshots/postbud/phone-4.png", "phone"),
  //     new Preview("/screenshots/postbud/phone-5.png", "phone"),
  //     new Preview("/screenshots/postbud/phone-6.png", "phone"),
  //     new Preview("/screenshots/postbud/phone-7.png", "phone"),
  //     new Preview("/screenshots/postbud/phone-8.png", "phone"),
  //   ],  // Leave this empty for previews
  //   [], // Leave this empty for videos
  //   null, // Leave this blank if no app demo link
  //   null, // Leave this blank if no web demo link
  //   "https://github.com/ArslanKamchybekov/PostBud"
  // )
];
