/* eslint-disable no-fallthrough */
import {
  STOCKFOLIO_ID,
  LMS_ID,
  REDDIT_ID,
  RECIPE_ID,
  EXPENSE_ID,
  SAAS_ID,
  PANTRY_ID,
  PROMPT_ID,
  POSTBUD_ID,
  ASONE_ID,
  BIAMINO_ID,
  GHAI_ID,
  AUTOMATA_ID,
} from "../../constants/projects";
import ProjectItem from "../../shared/models/ProjectItem";
import DualPhoneMockup from "./DualPhoneMockup";
import LaptopMockup from "./LaptopMockup";
import TabletMockup from "./TabletMockup";

const Mockup: React.FC<{ project: ProjectItem }> = (props) => {
  const { project } = props;
  const projectID = project.id;

  switch (projectID) {
    case REDDIT_ID:
    case STOCKFOLIO_ID:
    case LMS_ID:
    case RECIPE_ID:
    case EXPENSE_ID:    
    case SAAS_ID:
    case POSTBUD_ID:
    case ASONE_ID:
    case BIAMINO_ID: 
    case GHAI_ID: 
    case AUTOMATA_ID:
      return <LaptopMockup images={project.screenshots} />;
    case PANTRY_ID:
    case PROMPT_ID:
      return <TabletMockup images={project.screenshots} />;
    default:
      return <div></div>;
  }
};

export default Mockup;
